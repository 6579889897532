import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Col, Row, Popover } from 'antd'
import React from 'react'
import { useIntl } from 'react-intl'
import CloudImageUploader from './CloudImageUploader'

const FrameImageInput = React.forwardRef(({ children, form, landscapePreviewUrl, portraitPreviewUrl }, ref) => {
  const { getFieldValue, setFieldsValue } = form
  const intl = useIntl()
  const landscapeUrl = getFieldValue(['media', 'frameLandscapeUrl']) ?? landscapePreviewUrl
  const portraitUrl = getFieldValue(['media', 'framePortraitUrl']) ?? portraitPreviewUrl
  const onChangeFn = (field) => (value) => {
    console.log('changed', field, value)
    if (field === 'frameLandscapeUrl') {
      setFieldsValue({ media: { frameLandscapeUrl: value } })
    }
    if (field === 'framePortraitUrl') {
      setFieldsValue({ media: { framePortraitUrl: value } })
    }
  }

  return (
    <Row gutter={[12, 0]}>
      <Col xs={24} sm={12}>
        <div style={{ marginBottom: '15px' }}>
          {intl.formatMessage({ id: 'app.vertical', defaultMessage: 'Vertical' })}
          <Popover placement='right' content={<div style={{ width: 300 }}>{intl.formatMessage({ id: 'app.photoFrameInfo' })}</div>}>
            &nbsp;
            <ExclamationCircleOutlined />
          </Popover>
        </div>
        <div style={{ height: 'fit-content' }}>
          <CloudImageUploader
            placeholderUrl={portraitUrl}
            onChange={onChangeFn('framePortraitUrl')}
            value={portraitUrl}
            resize={{ height: 7200, width: 4800 }}
            accept='image/png'
            isTemporary
          >
            {children}
          </CloudImageUploader>
        </div>
      </Col>
      <Col xs={24} sm={12}>
        <div style={{ marginBottom: '15px' }}>
          {intl.formatMessage({ id: 'app.horizontal', defaultMessage: 'Horizontal' })}
          <Popover placement='right' content={<div style={{ width: 300 }}>{intl.formatMessage({ id: 'app.photoFrameInfo' })}</div>}>
            &nbsp;
            <ExclamationCircleOutlined />
          </Popover>
        </div>
        <div style={{ height: 'fit-content' }}>
          <CloudImageUploader
            placeholderUrl={landscapeUrl}
            onChange={onChangeFn('frameLandscapeUrl')}
            value={landscapeUrl}
            resize={{ height: 4800, width: 7200 }}
            accept='image/png'
            isTemporary
          >
            {children}
          </CloudImageUploader>
        </div>
      </Col>
    </Row>
  )
})

export default FrameImageInput
