import { Button, DatePicker, Form, Input } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  style: {
    lineHeight: 3,
    padding: 0,
  },
}

const EventForm = ({ onSubmit }) => {
  const [form] = Form.useForm()
  const intl = useIntl()
  const [loading, setLoading] = useState()
  const handleFormSubmit = async (values) => {
    setLoading(true)
    onSubmit(values)
    setLoading(false)
  }
  return (
    <div style={{ display: 'grid', justifyItems: 'center', width: '100%' }}>
      <Form
        form={form}
        layout='vertical'
        className='nonPrintDiv'
        onFinish={handleFormSubmit}
        scrollToFirstError={{ behavior: 'smooth' }}
        style={{ margin: '40px 28px 0 28px', maxWidth: '800px', width: '100%' }}
      >
        <div
          style={{
            display: 'grid',
            background: 'rgba(245, 245, 245, 1)',
            border: 'none',
            borderRadius: '8px',
            gap: '24px',
            padding: '40px',
          }}
        >
          <div>
            <Form.Item
              {...formItemLayout}
              name={['record', 'title']}
              label={intl.formatMessage({ id: 'app.eventTitle', defaultMessage: 'Event Title' })}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name={['date']}
              label={intl.formatMessage({ id: 'app.eventDate', defaultMessage: 'Event Date' })}
              rules={[{ required: true }]}
            >
              <DatePicker
                disabledDate={(current) => moment().add(-30, 'days') > current}
                style={{ borderRadius: '8px', minWidth: '100%' }}
              />
            </Form.Item>
          </div>
          <div
            style={{
              background: 'white',
              border: '1px solid LightGrey',
              borderRadius: '8px',
              display: 'grid',
              gap: '0 10px',
              gridTemplateColumns: '3fr 1fr',
              padding: '24px',
            }}
          >
            <div
              style={{
                alignItems: 'center',
                background: '#BD188B',
                borderRadius: '8px',
                color: 'white',
                display: 'grid',
                gridTemplateColumns: 'auto 1fr',
                height: '32px',
              }}
            >
              <div style={{ margin: '0 20px' }}>|</div>
              <div>{intl.formatMessage({ id: 'app.organizerShare', defaultMessage: 'Organizer Share' })}</div>
            </div>
            <div
              style={{
                alignItems: 'center',
                background: 'grey',
                borderRadius: '8px',
                color: 'white',
                display: 'grid',
                gridTemplateColumns: 'auto 1fr',
                height: '32px',
              }}
            >
              <div style={{ margin: '0 20px' }}>|</div>
              <div>{intl.formatMessage({ id: 'app.systemShare', defaultMessage: 'System Share' })}</div>
            </div>
            <div style={{ color: '#BD188B', fontSize: '70px', marginTop: '12px', textAlign: 'center' }}>75%</div>
            <div style={{ color: 'grey', fontSize: '70px', marginTop: '12px', textAlign: 'center' }}>25%</div>
          </div>
          <div style={{ display: 'grid', justifyContent: 'end' }}>
            <Button loading={loading} disabled={loading} type='primary' htmlType='submit' style={{ background: '#BD188B', border: 'none' }}>
              {intl.formatMessage({ id: 'app.createEvent', defaultMessage: 'Create Event' })}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  )
}

export { EventForm as default }
