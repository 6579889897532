import { CloseCircleOutlined } from '@ant-design/icons'
import { Checkbox, Form, Modal } from 'antd'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import tempUploadIcon from '../assets/icons/temp-upload.svg'
import CloudImageUploader from '../components/CloudImageUploader'

const EventDocumentForm = ({ event, tab }) => {
  const { consentDocumentUrl } = event ?? {}
  const intl = useIntl()
  const [termsModal, setTermsModal] = useState(false)
  const handleTermsModal = (value) => {
    setTermsModal(value)
  }
  return (
    <div
      className={tab === 'document' ? 'dropdown' : 'hide'}
      style={{
        border: '1px solid #DFDFDF',
        borderBottomLeftRadius: '12px',
        borderBottomRightRadius: '12px',
        borderTop: 'none',
        borderTopLeftRadius: '0',
        borderTopRightRadius: '0',
        padding: '8px 16px',
      }}
    >
      <Form.Item
        name={['record', 'consentDocumentUrl']}
        label={intl.formatMessage({ id: 'app.consentDocument', defaultMessage: 'Consent Document for selling photos on Photo ThaiRun' })}
        initialValue={consentDocumentUrl}
      >
        <CloudImageUploader path='events/cover' provider='cf' accept={'application/pdf, image/*'} resize={{ maxWidth: 1200, mode: 'contain' }}>
          <div style={{ display: 'grid', gap: '10px 0', justifyItems: 'center' }}>
            <img alt='temp-upload' src={tempUploadIcon} />
            <div
              style={{ color: '#777777', fontSize: '10px', fontWeight: '200', whiteSpace: 'pre-wrap' }}
            >{`Upload a file or drag and drop\nSupported file formate : PDF, JPG, PNG`}</div>
          </div>
        </CloudImageUploader>
      </Form.Item>
      <Form.Item
        name={['consentSellingPhotos']}
        valuePropName='checked'
        rules={[
          {
            required: tab === 'document',
            message: intl.formatMessage({ id: 'app.agreementRequired', defaultMessage: 'This agreement is required' }),
            validator: (_, value) => (value || tab !== 'document' ? Promise.resolve() : Promise.reject(new Error('This agreement is required'))),
          },
        ]}
      >
        <Checkbox>{`ข้าพเจ้ายืนยันว่าได้รับอนุญาติจากผู้จัดให้เป็นตัวแทนการเปิดระบบสำหรับขายภาพจากงานวิ่งตามที่ได้กรอกข้อมูลมา`}</Checkbox>
      </Form.Item>
      <Form.Item
        name={['policyAcknowledgement']}
        valuePropName='checked'
        rules={[
          {
            required: tab === 'document',
            message: intl.formatMessage({ id: 'app.agreementRequired', defaultMessage: 'This agreement is required' }),
            validator: (_, value) => (value || tab !== 'document' ? Promise.resolve() : Promise.reject(new Error('This agreement is required'))),
          },
        ]}
      >
        <Checkbox>
          <span>{`ข้าพเจ้าได้อ่านและทำความเข้าใจนโยบายของระบบอย่างครบถ้วนแล้ว และขอรับทราบและยินยอมตาม`}</span>
          <span onClick={() => handleTermsModal(true)} style={{ color: '#FB0606', textDecoration: 'underline' }}>{`นโยบายและข้อกำหนด`}</span>
          <span>{`ของทางไทยรัน`}</span>
        </Checkbox>
      </Form.Item>
      <Modal
        bodyStyle={{ borderRadius: '12px', padding: '24px 40px' }}
        closable
        closeIcon={<CloseCircleOutlined />}
        footer={null}
        maskClosable
        onCancel={() => handleTermsModal(false)}
        open={termsModal}
        style={{ borderRadius: '12px' }}
        title={intl.formatMessage({ id: 'app.photographerShareTerms', defaultMessage: 'Photographer Share Term of Use' })}
        width={500}
      >
        <div style={{ fontWeight: '300' }}>
          ส่วนแบ่งของช่างภาพในส่วนนี้ จะถูกแบ่งจากส่วนแบ่งของ <span style={{ fontWeight: '600' }}>Broker</span>{' '}
          มีจุดประสงค์เพื่ออำนวยความสะดวกในทางการเงิน<span style={{ fontWeight: '600' }}>ช่างภาพ</span>{' '}
          จะได้รับส่วนแบ่งจากภาพที่อัพโหลดและถูกซื้อผ่านระบบ ในส่วนของแพคเกจแบบเหมานั้นช่างภาพจะได้ส่วนแบ่งตามอัตราส่วนจากออเดอร์นั้นๆ
        </div>
      </Modal>
    </div>
  )
}

export { EventDocumentForm as default }
