import { Col, DatePicker, Form, Input, Row } from 'antd'
import moment from 'moment'
import React from 'react'
import { useIntl } from 'react-intl'
import tempUploadIcon from '../assets/icons/temp-upload.svg'
import CloudImageUploader from '../components/CloudImageUploader'
import FrameImageInput from '../components/FrameImageInput'
import config from '../config'

const EventGeneralForm = ({ event, form, tab }) => {
  const { coverUrl, photoFrame, searchableAt, slug, startDate, title } = event
  const intl = useIntl()
  return (
    <div
      className={tab === 'general' ? 'dropdown' : 'hide'}
      style={{
        border: '1px solid #DFDFDF',
        borderBottomLeftRadius: '12px',
        borderBottomRightRadius: '12px',
        borderTop: 'none',
        borderTopLeftRadius: '0',
        borderTopRightRadius: '0',
        padding: '8px 16px',
      }}
    >
      <Form.Item
        name={['record', 'slug']}
        label={intl.formatMessage({ id: 'app.url', defaultMessage: 'Url' })}
        initialValue={slug}
        rules={[
          { required: true },
          {
            pattern: /^[a-z0-9]+([/.-][a-z0-9]+)*$/i,
            message: intl.formatMessage({
              id: 'app.urlValidationMessage1',
              defaultMessage: 'Please input only English charactor and digit and - . /',
            }),
          },
        ]}
      >
        <Input addonBefore={config.webBaseUrl} />
      </Form.Item>
      <Form.Item
        name={['record', 'title']}
        label={intl.formatMessage({ id: 'app.eventTitle', defaultMessage: 'Event Title' })}
        initialValue={title}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Row gutter={[12, 0]}>
        <Col xs={24} sm={12}>
          <Form.Item
            name={['record', 'startDate']}
            label={intl.formatMessage({ id: 'app.eventDate', defaultMessage: 'Event Date' })}
            initialValue={startDate && moment(startDate)}
            rules={[{ required: true }]}
          >
            <DatePicker
              disabled={startDate && moment().isAfter(startDate, 'day')}
              disabledDate={(current) => moment().add(-30, 'days') > current}
              style={{ borderRadius: '8px', minWidth: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name={['record', 'searchableAt']}
            label={intl.formatMessage({ id: 'app.openSearchOn', defaultMessage: 'Start Upload On' })}
            initialValue={searchableAt && moment(searchableAt)}
            rules={[{ required: true }]}
          >
            <DatePicker showTime={{ minuteStep: 30, secondStep: 60 }} format='DD/MM/YYYY HH:mm:ss' style={{ borderRadius: '8px', width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name={['record', 'coverUrl']}
        label={intl.formatMessage({ id: 'app.coverImage', defaultMessage: 'Cover Image' })}
        initialValue={coverUrl}
        rules={[{ required: true }]}
      >
        <CloudImageUploader path='events/cover' provider='cf' accept='image/jpeg, image/png' resize={{ maxWidth: 1200, mode: 'contain' }}>
          <div style={{ display: 'grid', gap: '10px 0', justifyItems: 'center' }}>
            <img alt='temp-upload' src={tempUploadIcon} />
            <div style={{ color: '#777777', fontSize: '10px', fontWeight: '200', whiteSpace: 'pre-wrap' }}>{`${intl.formatMessage({
              id: 'app.clickOrDragDrop',
              defaultMessage: 'Click to upload or Drag and Drop files here',
            })}\n(jpg & png)`}</div>
          </div>
        </CloudImageUploader>
      </Form.Item>
      <Form.Item
        name={['media']}
        label={intl.formatMessage({ id: 'app.eventFrame', defaultMessage: 'Photo Frame' })}
        style={{ lineHeight: '1px', gridColumn: 'span 2' }}
      >
        <FrameImageInput form={form} landscapePreviewUrl={photoFrame?.landscapePreviewUrl} portraitPreviewUrl={photoFrame?.portraitPreviewUrl}>
          <div style={{ display: 'grid', gap: '10px 0', justifyItems: 'center' }}>
            <style>{`.ant-upload.ant-upload-drag { height: auto; }`}</style>
            <img alt='temp-upload' src={tempUploadIcon} />
            <div style={{ color: '#777777', fontSize: '10px', fontWeight: '200', whiteSpace: 'pre-wrap' }}>{`${intl.formatMessage({
              id: 'app.clickOrDragDrop',
              defaultMessage: 'Click to upload or Drag and Drop files here',
            })}\n(png)`}</div>
          </div>
        </FrameImageInput>
      </Form.Item>
    </div>
  )
}

export { EventGeneralForm as default }
