import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Col, Form, InputNumber, Row, Select } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import config from '../config'

const unlimitedPackageCredit = {
  70: {
    490: 50000,
    590: 80000,
    690: 100000,
    790: 150000,
  },
  80: {
    590: 100000,
    690: 150000,
    790: 200000,
    890: 500000,
    990: 800000,
  },
  90: {
    790: 250000,
    890: 500000,
    990: 800000,
  },
  100: {
    990: 800000,
    1190: 1500000,
  },
}

const EventPackageForm = ({ event, form, tab }) => {
  const { photoPackages, singlePhotoPrice, startDate } = event
  const _unlimitedPackage = photoPackages.find((photoPackage) => photoPackage.packageType === 'price_fixed')
  const packageCreditKeys = Object.keys(unlimitedPackageCredit[`${singlePhotoPrice}`] ?? {})
  const _credit = packageCreditKeys.reduce((prev, curr) => {
    if ((_unlimitedPackage?.amount ?? 0) >= Number(curr)) {
      return unlimitedPackageCredit[`${singlePhotoPrice}`][curr]
    }
    return prev
  }, 0)
  const { getFieldValue, setFieldsValue } = form
  const intl = useIntl()
  const [credit, setCredit] = useState(_credit)
  const [normalPackages, setNormalPackages] = useState([])
  const [unlimitedPackage, setUnlimitedPackage] = useState(_unlimitedPackage)
  const handlePriceChange = () => {
    setCredit(0)
    setFieldsValue({ packages: undefined })
    setNormalPackages([])
    setUnlimitedPackage()
  }
  const handleAddPackage = () => {
    setNormalPackages([...normalPackages, { photoCount: undefined, amount: undefined }])
  }
  const handleRemovePackage = (index) => {
    const packages = getFieldValue('packages') ?? {}
    const packageKeys = Object.keys(packages).sort()
    const newNormalPackages = packageKeys.reduce((prev, curr) => {
      const [, currentIndex, field] = curr.split('-')
      const indexNumber = Number(currentIndex)
      return indexNumber > index
        ? { ...prev, [`normalPackage-${indexNumber - 1}-${field}`]: packages[curr] }
        : indexNumber === index
        ? { ...prev, [`normalPackage-${normalPackages.length - 1}-${field}`]: undefined }
        : { ...prev, [curr]: packages[curr] }
    }, {})
    setNormalPackages((curr) => curr.filter((_, i) => i !== index))
    setFieldsValue({ packages: newNormalPackages })
  }
  const handleUnlimitedPackageChange = (value) => {
    const singlePhotoPrice = getFieldValue(['record', 'singlePhotoPrice'])
    const packageCreditKeys = Object.keys(unlimitedPackageCredit[`${singlePhotoPrice}`])
    const _credit = packageCreditKeys.reduce((prev, curr) => {
      if (value > Number(curr)) {
        return unlimitedPackageCredit[`${singlePhotoPrice}`][curr]
      }
      return prev
    }, 0)
    setCredit(_credit)
  }
  const normalPackageValidation = () => {
    const packages = Object.fromEntries(Object.entries(getFieldValue('packages') ?? {}).filter(([key]) => key.includes('minItemCount')))
    const values = Object.values(packages).filter((p) => !!p)
    const uniqueValues = new Set(values)
    const isUnique = uniqueValues.size === values.length
    if (isUnique) {
      return Promise.resolve()
    }
    return Promise.reject(new Error(intl.formatMessage({ id: 'app.duplicatePhotoCount', defaultMessage: 'Duplicate photo count value' })))
  }
  useEffect(() => {
    if (photoPackages) {
      const normalPacks = photoPackages.filter((photoPackage) => photoPackage.packageType !== 'price_fixed')
      const normalPackMaps = normalPacks.reduce(
        (prev, curr, index) => ({
          ...prev,
          [`normalPackage-${index}-minItemCount`]: curr.minItemCount,
          [`normalPackage-${index}-amount`]: curr.amount,
        }),
        {}
      )
      setNormalPackages(normalPacks)
      setFieldsValue({ packages: normalPackMaps })
    }
  }, [photoPackages, setFieldsValue])
  return (
    <div
      className={tab === 'package' ? 'dropdown' : 'hide'}
      style={{
        border: '1px solid #DFDFDF',
        borderBottomLeftRadius: '12px',
        borderBottomRightRadius: '12px',
        borderTop: 'none',
        borderTopLeftRadius: '0',
        borderTopRightRadius: '0',
        padding: '8px 16px',
      }}
    >
      <Row align={'bottom'} gutter={[12, 12]}>
        <Col xs={24} sm={12}>
          <Form.Item
            name={['record', 'singlePhotoPrice']}
            label={intl.formatMessage({ id: 'app.singlePhotoPrice', defaultMessage: 'Single Photo Price' })}
            initialValue={singlePhotoPrice}
            rules={[{ required: true }]}
          >
            <Select disabled={moment().isAfter(startDate, 'day')} onChange={handlePriceChange} style={{ maxWidth: '150px' }}>
              {config.singlePhotoPrices.map((singlePhotoPrice) => (
                <Select.Option key={singlePhotoPrice} value={singlePhotoPrice}>
                  {singlePhotoPrice}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} style={{ textAlign: 'end' }}>
          <Button onClick={handleAddPackage} style={{ marginBottom: '7px' }}>
            <PlusOutlined />
            {intl.formatMessage({ id: 'app.package', defaultMessage: 'Package' })}
          </Button>
        </Col>
      </Row>
      {normalPackages.map((normalPackage, index) => {
        return (
          <Row align={'top'} gutter={[12, 12]} key={index}>
            <Col xs={9} sm={4}>
              <Form.Item
                name={['packages', `normalPackage-${index}-minItemCount`]}
                label={intl.formatMessage({ id: 'app.photoCount', defaultMessage: 'Photo Count' })}
                initialValue={normalPackage.minItemCount}
                rules={[{ required: true, validator: normalPackageValidation }]}
              >
                <InputNumber />
              </Form.Item>
            </Col>
            <Col xs={9} sm={4}>
              <Form.Item noStyle shouldUpdate>
                {() => (
                  <Form.Item
                    name={['packages', `normalPackage-${index}-amount`]}
                    label={intl.formatMessage({ id: 'app.amount', defaultMessage: 'Amount' })}
                    initialValue={normalPackage.amount}
                    rules={[{ required: true }]}
                  >
                    <InputNumber disabled={!(getFieldValue(['packages', `normalPackage-${index}-minItemCount`]) > 0)} />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
            <Col xs={4}>
              <Form.Item noStyle shouldUpdate>
                {() => (
                  <Form.Item label={' '}>
                    {getFieldValue(['packages', `normalPackage-${index}-minItemCount`]) &&
                      getFieldValue(['packages', `normalPackage-${index}-amount`]) && (
                        <>
                          <span style={{ textDecorationLine: 'line-through' }}>{`${
                            singlePhotoPrice * getFieldValue(['packages', `normalPackage-${index}-minItemCount`])
                          }`}</span>
                          {` -> ${
                            singlePhotoPrice * getFieldValue(['packages', `normalPackage-${index}-minItemCount`]) -
                            getFieldValue(['packages', `normalPackage-${index}-amount`])
                          }`}
                        </>
                      )}
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
            <Col xs={2}>
              <DeleteOutlined onClick={() => handleRemovePackage(index)} />
            </Col>
          </Row>
        )
      })}
      <Row
        style={{
          display: 'grid',
          border: '1px solid #DFDFDF',
          borderRadius: '8px',
          gap: '10px',
          margin: '7px 0',
          padding: '12px',
        }}
      >
        <Col span={24}>
          <Form.Item
            name={['packages', `unlimitedPackage-0-amount`]}
            label={intl.formatMessage({ id: 'app.unlimitedPackageAmount', defaultMessage: 'Unlimited Package Amount' })}
            initialValue={unlimitedPackage?.amount}
          >
            <InputNumber onChange={handleUnlimitedPackageChange} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <div
          style={{
            alignItems: 'center',
            background: '#BD188B',
            borderRadius: '8px',
            color: 'white',
            display: 'grid',
            gridTemplateColumns: 'auto 1fr',
            height: '32px',
          }}
        >
          <div style={{ margin: '0 20px' }}>|</div>
          <div>{intl.formatMessage({ id: 'app.photoUploadCredits', defaultMessage: 'Photo Upload Credits' })}</div>
        </div>
        <div style={{ color: '#BD188B', fontSize: '70px', marginTop: '12px', textAlign: 'center' }}>{credit}</div>
      </Row>
    </div>
  )
}

export { EventPackageForm as default }
