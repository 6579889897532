import { useQuery } from '@apollo/client'
import { Button, Popover, Spin, Table } from 'antd'
import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import config from '../config'
import photoPackagesQuery from '../graphql/queries/photoPackages.gql'

const Packages = () => {
  const intl = useIntl()
  const { data, error, loading } = useQuery(photoPackagesQuery, { fetchPolicy: 'network-only' })
  const photoPackages = data?.photoPackages ?? []
  if (error) {
    return `${error}`
  }
  if (loading) {
    return <Spin style={{ display: 'block', lineHeight: '100vh' }} />
  }
  return (
    <>
      <div className='filterTools' style={{ textAlign: 'right', padding: '6px' }}>
        <Button type='primary'>
          <Link to='/packages/create'>{intl.formatMessage({ id: 'app.create', defaultMessage: 'Create' })}</Link>
        </Button>
      </div>
      <Table dataSource={photoPackages} loading={loading}>
        <Table.Column
          dataIndex={['displayName']}
          key='displayName'
          title={intl.formatMessage({ id: 'app.displayName', defaultMessage: 'Display Name' })}
        />
        <Table.Column
          dataIndex={['name']}
          key='name'
          render={(name, { _id, description }) => (
            <Popover content={description}>
              <Link to={`/packages/${_id}/edit`}>{name}</Link>
            </Popover>
          )}
          title={intl.formatMessage({ id: 'app.packageName', defaultMessage: 'Package Name' })}
        />
        <Table.Column
          dataIndex={['packageType']}
          key='packageType'
          title={intl.formatMessage({ id: 'app.packageType', defaultMessage: 'Package Type' })}
        />
        <Table.Column
          dataIndex={['minItemCount']}
          key='minItemCount'
          title={intl.formatMessage({ id: 'app.minPhotoCount', defaultMessage: 'Minimum Photo Count' })}
        />
        <Table.Column dataIndex={['photoSize']} key='photoSize' title={intl.formatMessage({ id: 'app.size', defaultMessage: 'Size' })} />
        <Table.Column
          key='discount'
          render={(p) => {
            if (p.packageType === 'discount_fixed') {
              return `-${p.amount} ${config.currency}`
            }
            if (p.packageType === 'discount_percentage') {
              return `-${p.amount}%`
            }
            if (p.packageType === 'price_fixed') {
              return `${p.amount} ${config.currency}`
            }
          }}
          title={`${intl.formatMessage({ id: 'app.price', defaultMessage: 'Price' })}/${intl.formatMessage({
            id: 'app.discount',
            defaultMessage: 'Discount',
          })}`}
        />
        <Table.Column
          dataIndex={['eventCount']}
          key='eventCount'
          title={intl.formatMessage({ id: 'app.eventCount', defaultMessage: 'Event Count' })}
        />
      </Table>
    </>
  )
}

export default Packages
